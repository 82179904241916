import React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import "./responsive.css"

import background from "../images/countdown.jpg"

const Layout = ({ children }) => {
  return (
    <>
      <div class="main-area center-text" style={{
        backgroundImage: `url(${background})`
      }}>
        <div className="display-table">
          <div className="display-table-cell">
            <main>{children}</main>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
